<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div v-if="blogContent">
      <v-row>
        <!-- Start Blog Area  -->
        <v-col
          lg="4"
          md="4"
          sm="6"
          cols="12"
          v-for="(item, i) in blogContent"
          :key="i"
        >
          <div class="blog blog-style--1">
            <div class="thumbnail">
              <router-link to="/blog-details">
                <img class="w-100" :src="item.src" alt="Blog Images" />
              </router-link>
            </div>
            <div class="content">
              <v-row>
                <v-chip
                  class="blogtype"
                  color="rgb(167, 167, 167, 0.7)"
                  text-color="rgb(255,255,255,0.7)"
                  v-for="(meta, i) in item.meta.data"
                  :key="i"
                >
                  {{ meta.attributes.name }}
                </v-chip>
              </v-row>
              <h4 class="heading-title">
                <router-link :to="'/blog/articulo/' + item.slug">{{
                  item.title
                }}</router-link>
              </h4>
              <div class="blog-btn">
                <router-link
                  :to="'/blog/articulo/' + item.slug"
                  class="rn-btn white--text"
                >
                  Leer
                </router-link>
              </div>
            </div>
          </div>
        </v-col>

        <!-- End Blog Area  -->
      </v-row>
      <v-row>
        <div class="slide-btn" style="margin-top:30px;">
          <router-link class="rn-button-style--2 btn_solid_blog" to="/blog"
            >Ir a blog</router-link
          >
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      overlay: true,
      page: 1,
      blogContent: null,
      paginationTotal: null,
    };
    props: ["pagination"];
  },
  mounted() {
    this.getData(1);
  },
  methods: {
    async getData(page) {
      this.overlay = true;
      try {
        const count = await axios(
          this.$apiUrl + "/api/posts/?fields[1]=meta.pagination.total"
        );
        let limit = 6;
        var auxArray = [];
        const defaultImageURL =
          "https://strapi-3-inflow-assets.s3.us-west-2.amazonaws.com/medium_wallpaperbetter_e1ef22eeee.jpg";

        try {
          const r = await axios(
            `${this.$apiUrl}/api/posts?sort=id:desc&pagination[page]=${page}&pagination[pageSize]=${limit}&populate=title_background,categories`
          );
          r.data.data.forEach((data) => {
            if (
              data.attributes.title_background.data !== null &&
              Object.keys(
                data.attributes.title_background.data.attributes.formats
              ).length > 0 &&
              "medium" in
                data.attributes.title_background.data.attributes.formats
            ) {
              auxArray.push({
                id: data.id,
                src: data.attributes.title_background.data.attributes.formats
                  .medium.url,
                meta: data.attributes.categories,
                title: data.attributes.title,
                slug: data.attributes.slug,
              });
            } else if (
              data.attributes.title_background.data !== null &&
              Object.keys(
                data.attributes.title_background.data.attributes.formats
              ).length > 0 &&
              "small" in
                data.attributes.title_background.data.attributes.formats
            ) {
              auxArray.push({
                id: data.id,
                src: data.attributes.title_background.data.attributes.formats
                  .small.url,
                meta: data.attributes.categories,
                title: data.attributes.title,
                slug: data.attributes.slug,
              });
            } else {
              auxArray.push({
                id: data.id,
                src: defaultImageURL,
                meta: data.attributes.categories,
                title: data.attributes.title,
                slug: data.attributes.slug,
              });
              console.log({
                id: data.id,
                src: defaultImageURL,
                meta: data.attributes.categories,
                title: data.attributes.title,
                slug: data.attributes.slug,
              });
            }
          });
        } catch (err) {
          console.log(err);
        }
        this.paginationTotal = count.data.meta.pagination.pageCount;
        this.blogContent = auxArray;

        this.overlay = false;
      } catch (err) {
        console.log(err);
        this.overlay = false;
      }
    },
  },
};
</script>
